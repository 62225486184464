export default {
  failed: 'Действие не удалось',
  success: 'Действие было успешным',
  common: {
    inputText: 'Пожалуйста, введите',
    selectText: 'Пожалуйста, выберите',
    startTimeText: 'Время начала',
    endTimeText: 'Время окончания',
    login: 'Логин',
    required: 'Это обязательно',
    loginOut: 'Выйти',
    document: 'Документ',
    profile: 'Центр пользователей',
    reminder: 'Напоминание',
    loginOutMessage: 'Выйти из системы?',
    back: 'вернут',
    ok: 'Да',
    save: 'Хранить',
    cancel: 'Отмена',
    close: 'Закрытый',
    reload: 'Перезагрузить текущий',
    success: 'Успех',
    closeTab: 'Закрыть ток',
    closeTheLeftTab: 'Закрыть слева',
    closeTheRightTab: 'Близко справа',
    closeOther: 'Закрыть другой',
    closeAll: 'Закрыть все',
    prevLabel: 'Предыдущая',
    nextLabel: 'Следующий',
    skipLabel: 'Прыжок',
    doneLabel: 'Конец',
    menu: 'Меню',
    menuDes: 'Строка меню отображается в маршрутной структуре',
    collapse: 'Коллапс',
    collapseDes: 'Развернуть и увеличить строку меню',
    tagsView: 'Просмотр тегов',
    tagsViewDes: 'Используется для записи истории маршрутизации',
    tool: 'Инструмент',
    toolDes: 'Используется для настройки пользовательских систем',
    query: 'Вопрос',
    reset: 'Снова ставить, снова поставить',
    shrink: 'Убрать',
    expand: 'Расширять, расширить',
    confirmTitle: 'Подсказка Системы',
    exportMessage: 'Подтверждать ли элемент экспортных данных?',
    importMessage: 'Подтверждать ли элемент импорта данных?',
    createSuccess: 'Создайте успех',
    updateSuccess: 'Успешное обновление',
    delMessage: 'Удалить выбранные данные?',
    delDataMessage: 'Удалить данные?',
    delNoData: 'Пожалуйста, выберите данные для удаления',
    delSuccess: 'Успешно удалено',
    index: 'Индекс',
    status: 'Статус',
    createTime: 'Создать Время',
    updateTime: 'Время обновления',
    copy: 'Копия',
    copySuccess: 'Успешное копирование',
    copyError: 'Копировать Ошибку',
    processing: 'Обработка...',
    submit: 'представ'
  },
  error: {
    noPermission: `Извините, у вас нет разрешения на доступ к этой странице.`,
    pageError: 'Извините, страница, которую вы посетили, не существует.',
    networkError: 'Извините, сервер сообщил об ошибке.',
    returnToHome: 'Возвращение домой'
  },
  login: {
    welcome: 'Добро пожаловать',
    message: 'Система управления за кулисами',
    tenantName: 'Имя арендатора',
    username: 'Имя пользователя',
    password: 'Пароль',
    code: 'Проверочный код',
    login: 'Войти',
    toSignUp: 'Создать новую учетную запись? ',
    signUp: 'Регистр',
    toLogin: 'Уже зарегистрирован? ',
    reLogin: 'Войдите снова',
    register: 'Регистр',
    checkPassword: 'Подтвердите пароль',
    remember: 'Помни меня',
    hasUser: 'Существующий аккаунт? Перейти к логину',
    forgetPassword: 'Забыли пароль?',
    usernamePlaceholder: 'Пожалуйста, введите имя пользователя',
    phoneNumberPlaceholder: 'Пожалуйста, Введите Номер Телефона',
    passwordPlaceholder: 'Пожалуйста, Введите Пароль',
    codePlaceholder: 'Пожалуйста, Введите Проверочный Код',
    phoneNumber: 'Номер Телефона',
    backLogin: 'Назад',
    loginBtn: 'Войти',
    registerBtn: 'Регистр',
    // 校验相关
    rules: {
      noPhoneError: 'Требуется номер телефона',
      noPasswordError: 'Требуется пароль',
      phoneNumberError: 'Пожалуйста, введите правильный номер телефона',
      passwordError: 'Пароль должен содержать от 6 до 20 символов'
    }
  },
  captcha: {
    verification: 'Пожалуйста, завершите проверку безопасности',
    slide: 'Проведите пальцем вправо, чтобы завершить верификацию',
    point: 'Пожалуйста, нажмите',
    success: 'Проверка прошла успешно',
    fail: 'Проверка не удалась'
  },
  router: {
    login: 'Логин',
    home: 'Дом',
    product: 'Продукт',
    wallet: 'Кошелёк',
    order: 'Приказ',
    mine: 'Мне',
    productDetail: 'Информация О Продукте',
    paymentRecords: 'Платежные записи',
    myTeam: 'Моя команда',
    payment: 'Оплата',
    paymentRecord: 'Платежная запись',
    aboutUs: 'О Нас',
    faq: 'Часто задаваемые вопросы',
    share: 'Доля'
  },
  form: {
    input: 'Вход',
    inputNumber: 'Входной номер',
    default: 'Значение по умолчанию',
    icon: 'Иконка',
    mixed: 'Смешанный',
    textarea: 'Текстовая она',
    slot: 'Временной интервал',
    position: 'Позиция',
    autocomplete: 'Автозаполнение',
    select: 'Выбирать',
    selectGroup: 'Выберите группу',
    selectV2: 'Выберите V2',
    cascader: 'Каскадер',
    switch: 'Переключатель',
    rate: 'Ставка',
    colorPicker: 'Выбор Цвета',
    transfer: 'Перевод',
    render: 'Представлять',
    radio: 'Радио',
    button: 'Кнопка',
    checkbox: 'Флажок',
    slider: 'Ползунок',
    datePicker: 'Выбор даты',
    shortcuts: 'Ключ',
    today: 'Сегодня',
    yesterday: 'Вчера',
    aWeekAgo: 'Неделю назад',
    week: 'Неделя',
    year: 'Год',
    month: 'Месяц',
    dates: 'Даты',
    daterange: 'Диапазон дат',
    monthrange: 'Месячный Диапазон',
    dateTimePicker: 'Выбор даты и времени',
    dateTimerange: 'Диапазон дат и времени',
    timePicker: 'Выбор времени',
    timeSelect: 'Выбор времени',
    inputPassword: 'Введите пароль',
    passwordStrength: 'Надежность пароля',
    operate: 'Оперировать',
    change: 'Изменение',
    restore: 'Восстанавливать',
    disabled: 'Инвалид',
    disablement: 'Инвалидность',
    delete: 'Удалять',
    add: 'Добавлять',
    setValue: 'Установить значение',
    resetValue: 'Сбросить значение',
    set: 'Набор',
    subitem: 'Подэтимент',
    formValidation: 'Проверка формы',
    verifyReset: 'Проверить сброс',
    remark: 'Замечание'
  },
  watermark: {
    watermark: 'Водяной знак'
  },
  dialog: {
    dialog: 'Диалог',
    open: 'Открытый',
    close: 'Закрытый'
  },
  sys: {
    api: {
      operationFailed: 'Операция не удалась',
      errorTip: 'Совет по ошибке',
      errorMessage: 'Операция не удалась, система ненормальна!',
      timeoutMessage: 'Время входа в систему истекло, пожалуйста, войдите снова!',
      apiTimeoutMessage: 'Время запроса на интерфейс истекло, пожалуйста, обновите страницу и повторите попытку!',
      apiRequestFailed: 'Запрос на интерфейс не удался, повторите попытку позже!',
      networkException: 'Сетевая аномалия',
      networkExceptionMsg: 'Пожалуйста, проверьте, нормально ли ваше сетевое соединение! Сеть ненормальна',
      errMsg401: 'У пользователя нет разрешения (токен, имя пользователя, ошибка пароля)!',
      errMsg403: 'Пользователь авторизован, но доступ запрещен!',
      errMsg404: 'Ошибка сетевого запроса, ресурс не найден!',
      errMsg405: 'Ошибка сетевого запроса, метод запроса не разрешен!',
      errMsg408: 'Время сетевого запроса истек!',
      errMsg500: 'Ошибка сервера, пожалуйста, свяжитесь с администратором!',
      errMsg501: 'Сеть не реализована!',
      errMsg502: 'Ошибка сети!',
      errMsg503: 'Сервис недоступен, сервер временно перегружен или поддерживается!',
      errMsg504: 'Тайм-аут сети!',
      errMsg505: 'Версия http не поддерживает запрос!',
      errMsg901: 'Демонстрационный режим, операции записи невозможна!'
    },
    app: {
      logoutTip: 'Напоминание',
      logoutMessage: 'Подтвердите выход из системы?',
      menuLoading: 'Загрузка меню...'
    },
    exception: {
      backLogin: 'Задний логин',
      backHome: 'Возвращение домой',
      subTitle403: 'Извините, у вас нет доступа к этой странице.',
      subTitle404: 'Извините, страница, которую вы посетили, не существует.',
      subTitle500: 'Извините, сервер сообщает об ошибке.',
      noDataTitle: 'На текущей странице нет данных.',
      networkErrorTitle: 'Сетевая Ошибка',
      networkErrorSubTitle: 'Извините, ваше сетевое соединение было отключено, пожалуйста, проверьте свою сеть!'
    }
  },
  profile: {
    user: {
      title: 'Личная информация',
      username: 'Имя пользователя',
      nickname: 'Ник',
      mobile: 'Номер Телефона',
      email: 'Почта пользователя',
      dept: 'Отдел',
      posts: 'Позиция',
      roles: 'Собственная Роль',
      sex: 'Секс',
      man: 'Человек',
      woman: 'Женщина',
      createTime: 'Дата создания'
    },
    info: {
      title: 'Основная Информация',
      basicInfo: 'Основная Информация',
      resetPwd: 'Сбросить пароль',
      userSocial: 'Социальная информация'
    },
    rules: {
      nickname: 'Пожалуйста, Введите ник пользователя',
      mail: 'Пожалуйста, Введите Адрес Электронной Почты',
      truemail: 'Пожалуйста, Введите Правильный Адрес Электронной Почты',
      phone: 'Пожалуйста, Введите Номер Телефона',
      truephone: 'Пожалуйста, Введите Правильный Номер Телефона'
    },
    password: {
      oldPassword: 'Старый PassWord',
      newPassword: 'Новый пароль',
      confirmPassword: 'Подтвердите пароль',
      oldPwdMsg: 'Пожалуйста, Введите Старый Пароль',
      newPwdMsg: 'Пожалуйста, Введите Новый Пароль',
      cfPwdMsg: 'Пожалуйста, введите пароль для подтверждения',
      pwdRules: 'Пароли должны содержать от 6 до 20 символов',
      diffPwd: 'Пароли, Введенные Дважды, Не Совпадают'
    }
  },
  user: {
    balance: 'Баланс',
    totalIncome: 'Общий Доход',
    todayIncome: 'Сегодняшний Доход',
    paymentRecords: 'Платежные записи'
  },
  product: {
    dailyIncome: 'Ежедневный Доход',
    totalIncome: 'Общий Доход',
    buy: 'Покупать',
    placeAnOrder: 'Купить сейчас',
    price: 'Цена',
    serving: 'Порция',
    day: 'День',
    days: 'Дней',
    viewDetail: 'Покупать >',
    investmentPlans: 'Инвестиционный план',
    investmentPlansInfo: 'Выберите пакетный план ниже, чтобы начать свой заработок. Нажмите на него, чтобы увеличить изображение и просмотреть дополнительную информацию о продукте.',
  },
  order: {
    dailyIncome: 'Ежедневный Доход',
    totalIncome: 'Общий Доход',
    alreadyObtained: 'Уже Получено',
    servingTime: 'Время обслуживания',
    day: 'День',
    days: 'Дней',
    dailyReturnTime: 'Ежедневное время возвращения',
    nextReturnTime: 'Предстоящее время заработка',
    price: 'Цена',
    orderNotPaid: 'Не оплачено',
  },
  bank: {
    card: 'Карточка',
    cardInfo: 'Информация О Карте',
    holderName: 'Имя владельца счета',
    bankName: 'Название Банка',
    cardNo: 'Номер счета',
    ifsc: 'IFSC',
    holderNamePlaceholder: 'Пожалуйста, введите свое полное имя',
    cardNoPlaceholder: 'Пожалуйста, Введите Номер Своего Счета',
    ifscPlaceholder: 'Пожалуйста, введите IFSC',
    rules: {
      noHolderNameError: 'Требуется имя владельца счета',
      noBankIdError: 'Пожалуйста, Выберите Название Банка',
      noCardNoError: 'Требуется Номер Счета',
      noIfscError: 'IFSC требуется',
      ifscError: 'Пожалуйста, введите правильный IFSC',
    }
  },
  withdraw: {
    withdraw: 'Снять деньги',
    withdrawRecord: 'Запись о снятии денег',
    withdrawAmount: 'Сумма вывода средств',
    withdrawAmountPlaceholder: 'Пожалуйста, введите сумму вывода',
    bankNotOk: 'Банковская информация неполная',
    // 校验相关
    rules: {
      noWithdrawAmountError: 'Требуется сумма вывода',
      withdrawAmountError: 'Сумма вывода должна быть больше минимальной суммы вывода средств'
    },
    aboutWithdraw: 'Объяснять',
    processTime: 'Время Обработки',
    minimumAmount: 'Минимальный вывод средств',
    arrivalTime: 'Время прибытия',
    fees: 'Сборы',
    withdrawBtn: 'Снять деньги',
    withdrawNo: 'Нет',
    price: 'Цена',
    withdrawStatus: {
      auditing: 'Обработка',
      approved: 'Обработка',
      success: 'Успех',
      'audit failed': 'Неудачный',
      'withdraw failed': 'Неудачный',
    }
  },
  payment: {
    productName: 'Имя',
    productPrice: 'Цена',
    chooseMethod: 'Выберите способ оплаты',
    chooseMethodError: 'Пожалуйста, выберите способ оплаты',
    pay: 'Зарплата',
    paySuccess: 'Оплатить Успех',
    payFailed: 'Оплата Не Удалась',
  },
  myTeam: {
    level: 'Уровень',
    numbers: 'Цифры',
    totalCommission: 'Общая комиссия',
    todayCommission: 'Сегодняшняя Комиссия',
    commission: {
      invite: 'Приглашение',
      commissionList: 'Список комиссий',
      totalRecharge: 'Полная Перезарядка',
      myCommission: 'Моя комиссия',
    },
  },
  share: {
    link: 'Ссылка',
    promotionMaterial: 'Рекламные Материалы',
    promotionContent: ''
  },
  paymentRecord: {
    paymentRecord: 'Платежные записи',
    remark: 'Если ваш продукт не отображается после оплаты, пожалуйста, следуйте этому, чтобы загрузить квитанцию о транзакции, которая должна содержать время оплаты, и не забудьте отправить нам на проверку, ваш платежный ордер будет подтвержден в течение максимум 24 часов',
    payStatus: {
      paid: 'Оплаченный',
      unPaid: 'Неоплаченный',
      pending: 'В ожидании'
    },
    orderNo: 'Номер',
    productName: 'Продукт',
    price: 'Цена',
    upload: 'Загрузка',
    uploadBtn: 'Загрузить доказательство',
    congrats: 'Поздравляю!',
    uploadProof: 'Загрузить доказательство',
    uploadImg: 'Пожалуйста, выберите скриншот.',
    uploadProofDesc: 'Описание',
    uploadProofDescPlaceHolder: 'Пожалуйста, Введите Описание'
  },
  mine: {
    myTeam: 'Моя команда',
    faq: 'Часто задаваемые вопросы',
    official: 'Официальный канал',
    paymentRecord: 'Платежная запись',
    referralLink: 'Реферальная Ссылка',
    aboutUs: 'О Нас',
    logOut: 'Выйти',
    logOutMessage: 'Вы уверены, что хотите выйти из системы?'
  }
}